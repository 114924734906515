import axios from 'axios'
import qs from "qs";

// actions
export const actions = {
    async destroy(_ctx, payload) {
        try {
            await axios.delete('/api/jenis-layanan/' + payload)
        } catch (error) {
            return []
        }
    },
    async update(_ctx, payload) {
        try {
            let data = await axios.patch('/api/jenis-layanan/' + payload.id, qs.stringify(payload.data))
            return data
        } catch (error) {
            return []
        }
    },
    async store(_ctx, payload) {
        try {
            let data = await axios.post('/api/jenis-layanan', qs.stringify(payload))
            return data
        } catch (error) {
            return []
        }
    },
    async index(_ctx, payload) {
        const { data } = await axios.get('/api/jenis-layanan?page=' + payload.page + '&nama_layanan=' + payload.data.nama_layanan)
        return data
    },
    async view(_ctx, payload) {
        const { data } = await axios.get('/api/jenis-layanan/view?id=' + payload.id)
        return data
    },
    async autocomplete() {
        const { data } = await axios.get('/api/jenis-layanan/autocomplete')
        return data
    },
    async autocomplete2() {
        const { data } = await axios.get('/api/jenis-layanan/autocomplete2')
        return data
    }
}
