
import axios from 'axios';
// actions
export const actions = {

    async autocomplete(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/master-jenis-dokumen/autocomplete')
            return data
        } catch (error) {
            return []
        }
    },
}
