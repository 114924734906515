import Vue from "vue";
import Router from "vue-router";

import { scrollBehavior, beforeEach } from "@/router/router";
// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

// const Colors = () => import('@/views/theme/Colors')
// const Typography = () => import('@/views/theme/Typography')

// const Charts = () => import('@/views/charts/Charts')
// const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
// const Cards = () => import('@/views/base/Cards')
// const Forms = () => import('@/views/base/Forms')
// const Switches = () => import('@/views/base/Switches')
// const Tables = () => import('@/views/base/Tables')
// const Tabs = () => import('@/views/base/Tabs')
// const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
// const Carousels = () => import('@/views/base/Carousels')
// const Collapses = () => import('@/views/base/Collapses')
// const Jumbotrons = () => import('@/views/base/Jumbotrons')
// const ListGroups = () => import('@/views/base/ListGroups')
// const Navs = () => import('@/views/base/Navs')
// const Navbars = () => import('@/views/base/Navbars')
// const Paginations = () => import('@/views/base/Paginations')
// const Popovers = () => import('@/views/base/Popovers')
// const ProgressBars = () => import('@/views/base/ProgressBars')
// const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
// const StandardButtons = () => import('@/views/buttons/StandardButtons')
// const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
// const Dropdowns = () => import('@/views/buttons/Dropdowns')
// const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
// const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
// const Brands = () => import('@/views/icons/Brands')
// const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
// const Alerts = () => import('@/views/notifications/Alerts')
// const Badges = () => import('@/views/notifications/Badges')
// const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");

// Users
// const Users = () => import('@/views/users/Users')
// const User = () => import('@/views/users/User')

// Jabatab Umum
const AbkUmum = () => import("@/views/jabatan_umum/abk/Index");
const InformasiUmum = () => import("@/views/jabatan_umum/informasi/Index");

// Jabatan Functional
const AbkFunctional = () => import("@/views/jabatan_functional/abk/Index");
const InformasiFunctional = () =>
  import("@/views/jabatan_functional/informasi/Index");

// Jabatan Stuktural
const KualifikasiJabatanStruktural = () => import("@/views/jabatan_stuktural/kualifikasi/Index");
const InformasiStuktural = () =>
  import("@/views/jabatan_stuktural/informasi/Index");

// Uji Kompetensi
const UjiKompetensi = () => import("@/views/uji_kompetensi/Index");

// Monitoring Jabatan Umum
const MJabatanStruktural = () => import("@/views/monitoring/jabatan_struktural/Index");

// Monitoring Jabatan Fungsional
const MJabatanFungsional = () =>
  import("@/views/monitoring/jabatan_fungsional/Index");

// Monitoring Jabatan Umum
const MJabatanUmum = () => import("@/views/monitoring/jabatan_umum/Index");

// Jenis Layanan
const JenisLayanan = () => import("@/views/layanan/jenis/Index");

// Jenis Layanan
const PersyaratanPelayanan = () =>
  import("@/views/layanan/persyaratan_pelayanan/Index.vue");

// Jenis Layanan
const UsulanKenaikanPangkat = () =>
  import("@/views/layanan/usulan_kenaikan_pangkat/Index.vue");
const VerifikasiKenaikanPangkat = () =>
  import("@/views/layanan/verifikasi_kenaikan_pangkat/Index.vue");
const UsulanMutasiKeluar = () =>
  import("@/views/layanan/UsulanMutasiKeluar/Index.vue");
const VerifikasiMutasiKeluar = () =>
  import("@/views/layanan/VerifikasiMutasiKeluar/Index.vue");

// Mutasi Masuk
const UsulanMutasiMasuk = () => import('@/views/layanan/usulaan_mutasi_masuk/Index.vue')
const VerifikasiUsulanMutasiMasuk = () => import('@/views/layanan/verifikasi_usulaan_mutasi_masuk/Index.vue')

// PencantumanGelar
const PencantumanGelar = () => import('@/views/layanan/pencantuman_gelar/Index.vue')
const VerifikasiPencantumanGelar = () => import('@/views/layanan/verifikasi_pencantuman_gelar/Index.vue')

// PerbaikanPergantian
const PerbaikanPergantian = () => import('@/views/layanan/usulan_perbaikan_pergantian/Index.vue')
const VerifikasiPerbaikanPergantian = () => import('@/views/layanan/verifikasi_perbaikan_pergantian/Index.vue')

// PerbaikanPergantian
const PerbaikanPergantianPengangkatan = () => import('@/views/layanan/perbaikan_pergantian_pengangkatan/Index.vue')
const VerifikasiPergantianPengangkatan = () => import('@/views/layanan/verifikasi_perbaikan_pergantian_pengangkatan/Index.vue')

// PerbaikanPergantian
const Role = () => import('@/views/managemen_user/role/Index.vue')
const Users = () => import('@/views/managemen_user/users/Users.vue')
const ChangePassword = () => import('@/views/pages/change_password.vue')

// Laporan
const PerOPDSKPD = () =>
  import("@/views/laporan/per_opd_skpd/Index.vue");
const PerEselon = () =>
  import("@/views/laporan/per_eselon/Index.vue");
const PerJenisKelamin = () =>
  import("@/views/laporan/per_jenis_kelamin/Index.vue");
const PerGolongan = () =>
  import("@/views/laporan/per_golongan/Index.vue");
const PerPendidikan = () =>
  import("@/views/laporan/per_pendidikan/Index.vue");

Vue.use(Router);

let router = new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: scrollBehavior,
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/dashboard",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
          meta: { keyItem: null, keySubItem: null, keyAction: null, noAuth: true },
        },
        {
          path: "management-users",
          redirect: "/management-users/users",
          name: "ManagementUser",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "users",
              name: "Users",
              component: Users,
              meta: { keyItem: null, keySubItem: null, keyAction: null, noAuth: false }
            },
            {
              path: "roles",
              name: "Roles",
              component: Role,
              meta: { keyItem: null, keySubItem: null, keyAction: null, noAuth: false }
            },
            {
              path: "change_password",
              name: "ChangePassword",
              meta: { keyItem: null, keySubItem: null, keyAction: null, noAuth: true }
            },
          ]
        },
        {
          path: "layanan",
          redirect: "/layanan/jenis-layanan",
          name: "Layanan",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "jenis-layanan",
              name: "Jenis Layanan",
              component: JenisLayanan,
              meta: { keyItem: 'layanan', keySubItem: 'jenis_layanan', keyAction: 'index', noAuth: false }
            },
            {
              path: "persyaratan-pelayanan",
              name: "Persyaratan Pelayanan",
              component: PersyaratanPelayanan,
              meta: { keyItem: 'layanan', keySubItem: 'persyaratan_layanan', keyAction: 'index', noAuth: false }
            }
          ],
        },
        // {
        //   path: "verifikasi-layanan",
        //   redirect: "/verifikasi-layanan/verifikasi-kenaikan-pangkat",
        //   name: "Verifikasi Layanan",
        //   component: {
        //     render(c) {
        //       return c("router-view");
        //     },
        //   },
        //   children: [
        //     {
        //       path: "verifikasi-kenaikan-pangkat",
        //       name: "Verifikasi Kenaikan Pangkat",
        //       component: VerifikasiKenaikanPangkat,
        //       meta: { keyItem: 'verifikasi_layanan', keySubItem: 'kenaikan_pangkat', keyAction: 'index', noAuth: false }
        //     },
        //     {
        //       path: 'verifikasi-usulan-mutasi-masuk',
        //       name: 'Verifikasi Usulan Mutasi Masuk',
        //       component: VerifikasiUsulanMutasiMasuk,
        //       meta: { keyItem: 'verifikasi_layanan', keySubItem: 'usulan_mutasi_masuk', keyAction: 'index', noAuth: false }
        //     },
        //     {
        //       path: 'verifikasi-pencantuman-gelar',
        //       name: 'Verifikasi Pencantuman Gelar',
        //       component: VerifikasiPencantumanGelar,
        //       meta: { keyItem: 'verifikasi_layanan', keySubItem: 'pencantuman_gelar', keyAction: 'index', noAuth: false }
        //     },
        //     {
        //       path: 'verifikasi-perbaikan-pergantian',
        //       name: 'Verifikasi Perbaikan dan Pergantian SK Kenaikan Pangkat',
        //       component: VerifikasiPerbaikanPergantian,
        //       meta: { keyItem: 'verifikasi_layanan', keySubItem: 'pnp_sk_kenaikan_pangkat', keyAction: 'index', noAuth: false }
        //     },
        //     {
        //       path: 'verifikasi-perbaikan-pergantian-pengangkatan',
        //       name: 'Verifikasi Usulan Perbaikan dan Pergantian SK Pengangkatan',
        //       component: VerifikasiPergantianPengangkatan,
        //       meta: { keyItem: 'verifikasi_layanan', keySubItem: 'pnp_sk_pengangkatan', keyAction: 'index', noAuth: false }
        //     },
        //     {
        //       path: "verifikasi-mutasi-keluar",
        //       name: "Verifikasi Mutasi Keluar",
        //       component: VerifikasiMutasiKeluar,
        //       meta: { keyItem: 'verifikasi_layanan', keySubItem: 'mutasi_keluar', keyAction: 'index', noAuth: false }
        //     },
        //   ],
        // },
        {
          path: "laporan",
          redirect: "/laporan/per-opd-skpd",
          name: "Laporan",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "per-opd-skpd",
              name: "Per OPD/SKPD",
              component: PerOPDSKPD,
              meta: { keyItem: 'laporan', keySubItem: 'per-opd-skpd', keyAction: 'index', noAuth: false }
            },
            {
              path: "per-eselon",
              name: "Per Eselon",
              component: PerEselon,
              meta: { keyItem: 'laporan', keySubItem: 'per-eselon', keyAction: 'index', noAuth: false }
            },
            {
              path: "per-jenis-kelamin",
              name: "Per Jenis Kelamin",
              component: PerJenisKelamin,
              meta: { keyItem: 'laporan', keySubItem: 'per-jenis-kelamin', keyAction: 'index', noAuth: false }
            },
            {
              path: "per-golongan",
              name: "Per Golongan",
              component: PerGolongan,
              meta: { keyItem: 'laporan', keySubItem: 'per-golongan', keyAction: 'index', noAuth: false }
            },
            {
              path: "per-pendidikan",
              name: "Per Pendidikan",
              component: PerPendidikan,
              meta: { keyItem: 'laporan', keySubItem: 'per-pendidikan', keyAction: 'index', noAuth: false }
            },
          ],
        },
        // {
        //   path: "usulan-layanan",
        //   redirect: "/usulan-layanan/usulan-kenaikan-pangkat",
        //   name: "Usulan Layanan",
        //   component: {
        //     render(c) {
        //       return c("router-view");
        //     },
        //   },
        //   children: [
        //     {
        //       path: "usulan-kenaikan-pangkat",
        //       name: "Usulan Kenaikan Pangkat",
        //       component: UsulanKenaikanPangkat,
        //       meta: { keyItem: 'usulan_layanan', keySubItem: 'usulan_kenaikan_pangkat', keyAction: 'index', noAuth: false }
        //     },
        //     {
        //       path: "usulan-mutasi-keluar",
        //       name: "Usulan Mutasi Keluar",
        //       component: UsulanMutasiKeluar,
        //       meta: { keyItem: 'usulan_layanan', keySubItem: 'usulan_mutasi_masuk', keyAction: 'index', noAuth: false }
        //     },
        //     {
        //       path: 'usulan-mutasi-masuk',
        //       name: 'Usulan Mutasi Masuk',
        //       component: UsulanMutasiMasuk,
        //       meta: { keyItem: 'usulan_layanan', keySubItem: 'usulan_mutasi_keluar', keyAction: 'index', noAuth: false }
        //     },
        //     {
        //       path: 'pencantuman-gelar',
        //       name: 'Pencantuman Gelar',
        //       component: PencantumanGelar,
        //       meta: { keyItem: 'usulan_layanan', keySubItem: 'pencantuman_gelar', keyAction: 'index', noAuth: false }
        //     },
        //     {
        //       path: 'perbaikan-pergantian',
        //       name: 'Perbaikan dan Pergantian SK Kenaikan Pangkat',
        //       component: PerbaikanPergantian,
        //       meta: { keyItem: 'usulan_layanan', keySubItem: 'sk_kenaikan_pangkat', keyAction: 'index', noAuth: false }
        //     },
        //     {
        //       path: 'perbaikan-pergantian-pengangkatan',
        //       name: 'Usulan Perbaikan dan Pergantian SK Pengangkatan',
        //       component: PerbaikanPergantianPengangkatan,
        //       meta: { keyItem: 'usulan_layanan', keySubItem: 'sk_pengangkatan', keyAction: 'index', noAuth: false }
        //     },
        //   ],
        // },
        {
          path: "admin",
          redirect: "/admin/abk-jabatan-umum",
          name: "Admin",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "kualifikasi-jabatan-struktural",
              name: "Kualifikasi Jabatan Struktural",
              component: KualifikasiJabatanStruktural,
              meta: { keyItem: 'admin', keySubItem: 'kualifikasi_jabatan_strukturalx', keyAction: 'index', noAuth: false }
            },
            {
              path: "abk-jabatan-umum",
              name: "ABK Jabatan Umum",
              component: AbkUmum,
              meta: { keyItem: 'admin', keySubItem: 'abk_jabatan_umum', keyAction: 'index', noAuth: false }
            },
            {
              path: "informasi-jabatan-umum",
              name: "Informasi Jabtan Umum",
              component: InformasiUmum,
              meta: { keyItem: 'admin', keySubItem: 'informasi_jabatan_umum', keyAction: 'index', noAuth: false }
            },
            {
              path: "abk-jabatan-functional",
              name: "ABK Jabatan Functional",
              component: AbkFunctional,
              meta: { keyItem: 'admin', keySubItem: 'abk_jabatan_fungsional', keyAction: 'index', noAuth: false }
            },
            {
              path: "informasi-jabatan-functional",
              name: "Informasi Jabatan Functional",
              component: InformasiFunctional,
              meta: { keyItem: 'admin', keySubItem: 'informasi_jabatan_functional', keyAction: 'index', noAuth: false }
            },
            {
              path: "informasi-jabatan-stuktural",
              name: "Informasi Jabatan Struktural",
              component: InformasiStuktural,
              meta: { keyItem: 'admin', keySubItem: 'informasi_jabatan_stuktural', keyAction: 'index', noAuth: false }
            },
          ],
        },

        {
          path: "uji-kompetensi",
          name: "Uji Kompetensi",
          component: UjiKompetensi,
          meta: { keyItem: 'uji_kompetensi', keySubItem: null, keyAction: 'index', noAuth: false },
        },
        {
          path: "monitoring-jabatan-struktural",
          name: "Monitoring Jabatan Struktural",
          component: MJabatanStruktural,
          meta: { keyItem: 'monitoring_jabatan_struktural', keySubItem: null, keyAction: 'index', noAuth: false },
        },
        {
          path: "monitoring-jabatan-fungsional",
          name: "Monitoring Jabatan Fungsional",
          component: MJabatanFungsional,
          meta: { keyItem: 'monitoring_jabatan_fungsional', keySubItem: null, keyAction: 'index', noAuth: false },
        },
        {
          path: "monitoring-jabatan-umum",
          name: "Monitoring Jabatan Umum",
          component: MJabatanUmum,
          meta: { keyItem: 'monitoring_jabatan_umum', keySubItem: null, keyAction: 'index', noAuth: false },
        },

        // {
        //   path: 'theme',
        //   redirect: '/theme/colors',
        //   name: 'Theme',
        //   component: {
        //     render(c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'colors',
        //       name: 'Colors',
        //       component: Colors
        //     },
        //     {
        //       path: 'typography',
        //       name: 'Typography',
        //       component: Typography
        //     }
        //   ]
        // },
        // {
        //   path: 'charts',
        //   name: 'Charts',
        //   component: Charts
        // },
        // {
        //   path: 'widgets',
        //   name: 'Widgets',
        //   component: Widgets
        // },
        // {
        //   path: 'users',
        //   meta: {
        //     label: 'Users'
        //   },
        //   component: {
        //     render(c) {
        //       return c('router-view')
        //     }
        //   },
        //   children: [
        //     {
        //       path: '',
        //       name: 'Users',
        //       component: Users
        //     },
        //     {
        //       path: ':id',
        //       meta: {
        //         label: 'User Details'
        //       },
        //       name: 'User',
        //       component: User
        //     }
        //   ]
        // },
        // {
        //   path: 'base',
        //   redirect: '/base/cards',
        //   name: 'Base',
        //   component: {
        //     render(c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'cards',
        //       name: 'Cards',
        //       component: Cards
        //     },
        //     {
        //       path: 'forms',
        //       name: 'Forms',
        //       component: Forms
        //     },
        //     {
        //       path: 'switches',
        //       name: 'Switches',
        //       component: Switches
        //     },
        //     {
        //       path: 'tables',
        //       name: 'Tables',
        //       component: Tables
        //     },
        //     {
        //       path: 'tabs',
        //       name: 'Tabs',
        //       component: Tabs
        //     },
        //     {
        //       path: 'breadcrumbs',
        //       name: 'Breadcrumbs',
        //       component: Breadcrumbs
        //     },
        //     {
        //       path: 'carousels',
        //       name: 'Carousels',
        //       component: Carousels
        //     },
        //     {
        //       path: 'collapses',
        //       name: 'Collapses',
        //       component: Collapses
        //     },
        //     {
        //       path: 'jumbotrons',
        //       name: 'Jumbotrons',
        //       component: Jumbotrons
        //     },
        //     {
        //       path: 'list-groups',
        //       name: 'List Groups',
        //       component: ListGroups
        //     },
        //     {
        //       path: 'navs',
        //       name: 'Navs',
        //       component: Navs
        //     },
        //     {
        //       path: 'navbars',
        //       name: 'Navbars',
        //       component: Navbars
        //     },
        //     {
        //       path: 'paginations',
        //       name: 'Paginations',
        //       component: Paginations
        //     },
        //     {
        //       path: 'popovers',
        //       name: 'Popovers',
        //       component: Popovers
        //     },
        //     {
        //       path: 'progress-bars',
        //       name: 'Progress Bars',
        //       component: ProgressBars
        //     },
        //     {
        //       path: 'tooltips',
        //       name: 'Tooltips',
        //       component: Tooltips
        //     }
        //   ]
        // },
        // {
        //   path: 'buttons',
        //   redirect: '/buttons/standard-buttons',
        //   name: 'Buttons',
        //   component: {
        //     render(c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'standard-buttons',
        //       name: 'Standard Buttons',
        //       component: StandardButtons
        //     },
        //     {
        //       path: 'button-groups',
        //       name: 'Button Groups',
        //       component: ButtonGroups
        //     },
        //     {
        //       path: 'dropdowns',
        //       name: 'Dropdowns',
        //       component: Dropdowns
        //     },
        //     {
        //       path: 'brand-buttons',
        //       name: 'Brand Buttons',
        //       component: BrandButtons
        //     }
        //   ]
        // },
        // {
        //   path: 'icons',
        //   redirect: '/icons/coreui-icons',
        //   name: 'CoreUI Icons',
        //   component: {
        //     render(c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'coreui-icons',
        //       name: 'Icons library',
        //       component: CoreUIIcons
        //     },
        //     {
        //       path: 'brands',
        //       name: 'Brands',
        //       component: Brands
        //     },
        //     {
        //       path: 'flags',
        //       name: 'Flags',
        //       component: Flags
        //     }
        //   ]
        // },
        // {
        //   path: 'notifications',
        //   redirect: '/notifications/alerts',
        //   name: 'Notifications',
        //   component: {
        //     render(c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'alerts',
        //       name: 'Alerts',
        //       component: Alerts
        //     },
        //     {
        //       path: 'badges',
        //       name: 'Badges',
        //       component: Badges
        //     },
        //     {
        //       path: 'modals',
        //       name: 'Modals',
        //       component: Modals
        //     }
        //   ]
        // }
      ]
    },
    {
      path: "*",
      redirect: "/pages/404",
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
          meta: { keyItem: null, keySubItem: null, keyAction: null, noAuth: true }
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
          meta: { keyItem: null, keySubItem: null, keyAction: null, noAuth: true }
        },
        {
          path: "login",
          name: "Login",
          component: Login,
          meta: { keyItem: null, keySubItem: null, keyAction: null, noAuth: true }
        },
        {
          path: "register",
          name: "Register",
          component: Register,
        },
      ],
    },
  ];
}

router.beforeEach(beforeEach);

export default router;
