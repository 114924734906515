
import axios from 'axios';
// actions
export const actions = {

    async autocomplete(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/d-identitas/autocomplete?search=' + payload)
            return data
        } catch (error) {
            return []
        }
    },


    async document(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/d-identitas/document?nip=' + payload.nip + '&id=' + payload.id)
            return data
        } catch (error) {
            return []
        }
    },
}
